@import "~antd/dist/antd.css";
.App {
  text-align: center;
}

.App-logo {
  padding: 15px;
}

.calendar-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  padding: 10px;
  /* background-color: #2c2f33; */
}

.Avatar-circle {
  background-color: #1890ff;
  margin-right: 10px;
}

.Avatar-text {
  color: #1890ff;
  font-weight: bold;
}

.App-content {
  min-height: calc(100vh - 64px);
  padding: calc(0px + 1vw);
}

/* .App-sider {
  background-color: #2c2f33;
} */

.ant-layout-sider-children {
  height: 100vh;
}

.side-menu-item {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.1em;
}

.side-menu-item-icon {
  color: #fff;
  padding: 0px 5px;
}

.card-stats {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.antd-stats-title {
  color: #1890ff;
  font-size: calc(16px + 1vw);
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
}

.fancy-titles {
  font-weight: 700;
  font-size: 1.1em;
  font-family: "Roboto", sans-serif;
}

.subtle-text {
  color: #bbb;
  font-size: calc(12px);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.break-by-word {
  word-break: break-word;
}

.template-stat {
  font-size: calc(16px + 1vw);
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.name-preview {
  font-size: calc(14px);
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #2c2f33;
}

.name-preview-subtitle {
  font-size: calc(12px);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #666;
}

.data-row {
  border-bottom: 1px solid #e5e5e5;
}

.data-group {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.data-key {
  font-weight: bold;
  color: #666;
}

.ant-form-item-label > label {
  color: #1890ff;
}

.ant-modal-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #1890ff;
}

.ant-modal-title {
  color: #fff;
}

.ant-modal-content {
  border-radius: 20px;
}

.data-value {
  font-weight: 400;
  text-align: right;
  font-family: "Roboto", sans-serif;
}

.data-value-longText {
  font-weight: 400;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
